<template>
  <div class="home">
    <div class="header-container" v-show="!isManageSub">
      <div class="wrapper">
        <a class="logo" :href="websiteUrl" :title="vendorInfo.name">
          <span :class="['logo-icon', vendorInfo.vendor]"
            ><img loading="lazy" :src="vendorInfo.logo" alt="logo"
          /></span>
          <span v-if="!vendorInfo.withoutName">{{ vendorInfo.name }}</span>
        </a>
        <div class="header-right">
          <div class="custom-select" :class="isSelect ? 'active' : ''">
            <p @click.stop="toSelectLan">
              <span>{{ lanText }}</span>
              <span></span>
            </p>
            <div
              class="select-options"
              ref="selectLanContainer"
              v-show="isSelect"
            >
              <p
                v-for="(l, i) in allLanguages"
                :key="i"
                :class="lanText == l.label ? 'active' : ''"
                @click="changeLan(l.value)"
              >
                {{ l.label }}
              </p>
            </div>
          </div>
          <div style="display: flex" v-if="systemHasLogin">
            <p
              class="p-btn logout-btn"
              v-if="screenWidth > 992"
              @click="handleLogout($event)"
              :class="aborting ? 'isLoading' : ''"
            >
              {{ $t("home.logout") }}
            </p>
            <p
              class="menu-btn"
              :class="showMenu ? 'active' : ''"
              v-else
              @click="changeMenuStatus"
            >
              <span class="s1"></span>
              <span class="s2"></span>
              <span class="s3"></span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <router-view class="main-content"></router-view>
    <div class="footer" v-show="!isManageSub">
      <p>COPYRIGHT © 2025 {{ vendorInfo.name }} All rights Reserved</p>
    </div>
    <p class="scroll-btn" v-show="showScrollButton">
      <img
        loading="lazy"
        @click="scrollToTop"
        src="@/assets/img/icon/arrow.svg"
        alt="arrow"
      />
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { logout } from "@/api/login.js";
import { updateData } from "@/utils/parse.js";

export default {
  name: "homePage",
  components: {},
  data() {
    return {
      isSelect: false,
      allLanguages: [
        {
          value: "en_US",
          label: "English",
          dmLink: "/",
        },
        {
          value: "fr_FR",
          label: "Français",
          dmLink: "/fr/",
        },
        {
          value: "de_DE",
          label: "Deutsch",
          dmLink: "/de/",
        },
        {
          value: "es_ES",
          label: "Español",
          dmLink: "/es/",
        },
        {
          value: "zh_TW",
          label: "繁體中文",
          dmLink: "/zh-TW/",
        },
        {
          value: "ja_JP",
          label: "日本語",
        },
        {
          value: "it_IT",
          label: "Italiano",
          dmLink: "/it/",
        },
        {
          value: "ko_KR",
          label: "한국어",
          dmLink: "/ko/",
        },
        {
          value: "nl_NL",
          label: "Nederlands",
          dmLink: "/nl/",
        },
        {
          value: "pl_PL",
          label: "Polski",
          dmLink: "/pl/",
        },
        {
          value: "pt_PT",
          label: "Português",
          dmLink: "/pt/",
        },
      ],
      showScrollButton: false,
      lanText: this.$t("choose_lang"),
      aborting: false,
    };
  },
  computed: {
    isManageSub() {
      const name = this.$route.name;
      return (
        name == "dmViewReceipt" ||
        name == "dmManageSubscription" ||
        name == "dmChangePaymentMethod" ||
        name == "dmAddNewCard" ||
        name == "dmOrderComplete"
      );
    },
    ...mapGetters([
      "systemHasLogin",
      "showMenu",
      "screenWidth",
      "isPro",
      "vendorInfo",
    ]),
    websiteUrl() {
      return (
        this.vendorInfo.officialLink[this.$i18n.locale] ||
        this.vendorInfo.officialLink["en_US"] ||
        ""
      );
    },
  },
  methods: {
    changeLan(value) {
      localStorage.setItem("language", value);
      window.location.reload();
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    handleLogout(e) {
      this.aborting = true;
      logout()
        .then(() => {
          this.$store.commit("dmCommon/changeLoginStatus", false);
          this.$navigateTo(e, "/login");
          this.$store.commit("user/setUserInfo", null);
          this.$cookieManager.removeCookie("_token");
          // removeCookie("_token");
        })
        .catch(() => {})
        .finally(() => {
          this.aborting = false;
        });
    },
    changeMenuStatus() {
      this.$store.commit("dmCommon/changeMenuStatus", !this.showMenu);
    },
    toSelectLan() {
      this.isSelect = !this.isSelect;
      if (this.isSelect) {
        document.addEventListener("click", this.closeSelect);
      }
    },
    closeSelect() {
      this.isSelect = false;
      document.removeEventListener("click", this.closeSelect);
    },
  },
  mounted() {
    updateData({ vendor: this.vendorInfo.vendor });

    if (
      this.vendorInfo.name == "TuneFab" &&
      process.env.NODE_ENV == "production"
    ) {
      const script = document.createElement("script");
      script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-WS2BKGD5');`;
      const titleElement = document.head.querySelector("title");
      document.head.insertBefore(script, titleElement.nextSibling);

      const noscript = document.createElement("noscript");
      noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WS2BKGD5" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
      document.body.insertBefore(noscript, document.body.firstChild);
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 50) {
        this.showScrollButton = true;
      } else {
        this.showScrollButton = false;
      }
    });
    window.addEventListener("resize", () => {
      this.$store.commit("common/updateWidth", window.innerWidth);
    });

    // 软件带语种
    const targetLan = this.$route.query.lang;
    const currentLan = localStorage.getItem("language");
    const isTw = targetLan == "hk" || targetLan == "zh-TW";
    if (
      currentLan &&
      (targetLan == currentLan.slice(0, 2) || (isTw && currentLan == "zh_TW"))
    ) {
      Object.keys(this.$route.query).length > 1
        ? ""
        : this.$router.replace({ query: {} });
      return;
    }
    if (targetLan) {
      const res = this.allLanguages.filter(language => {
        return language.value.includes(targetLan);
      });
      if (isTw) {
        this.changeLan("zh_TW");
      } else {
        if (res[0]) {
          res[0].value == currentLan ? "" : this.changeLan(res[0].value);
        } else {
          currentLan == "en_US" ? "" : this.changeLan("en_US");
        }
      }
    }

    let item = this.allLanguages.find(function (language) {
      return language.value === localStorage.getItem("language");
    });
    if (item && item.label != this.$t("choose_lang")) {
      window.location.reload();
    }
  },
};
</script>

<style lang="scss">
@import "@/styles/dmHome";
</style>
