<template>
  <div
    class="popup-continer"
    v-if="isError || isSuccess || isWarning || isCustom || isStopSub"
  >
    <div v-if="isSuccess || isError" class="custom-popup success-popup">
      <img
        loading="lazy"
        :src="require(`@/assets/img/icon/${!isError ? 'success' : 'fail'}.svg`)"
        :alt="isError ? 'fail' : 'success'"
      />
      <slot v-if="$slots['popup-content']" name="popup-content"></slot>
      <template v-else>
        <p class="text">{{ text }}</p>
        <p
          class="p-btn usable"
          @click="accountPresence ? toLogin($event) : handleOk()"
        >
          {{ accountPresence ? $t("login.login") : $t("popup.ok") }}
        </p>
      </template>
    </div>
    <div v-if="isWarning" class="custom-popup warn-popup">
      <img loading="lazy" src="@/assets/img/icon/warning.svg" alt="warning" />
      <p>{{ text }}</p>
      <div>
        <p
          class="p-btn usable"
          :class="[
            customConfirm && customConfirm.type == 'red' ? 'red-btn' : '',
            isWaiting ? 'loading' : '',
          ]"
          @click="handleConfirm"
        >
          {{
            isWaiting
              ? ""
              : this.customConfirm
              ? this.customConfirm.text
              : $t("popup.confirm")
          }}
        </p>
        <p class="p-btn popup-cancel" @click="handleCancel">
          {{ $t("popup.cancel") }}
        </p>
      </div>
    </div>
    <div v-if="isStopSub" class="custom-popup stop-popup">
      <label v-for="(o, i) in options" :key="i">
        <input type="radio" :value="i" v-model="selectedReason" />
        <span>{{ options[i] }}</span>
        <input
          type="text"
          v-if="selectedReason == i && i != 1"
          v-model="specificReason"
        />
      </label>
      <p
        class="p-btn usable"
        :class="[canComfirm ? '' : 'disabled', inFeedback ? 'isLoading' : '']"
        @click="handleSubmit"
      >
        {{ $t("popup.confirm") }}
      </p>
      <p class="p-btn popup-cancel" @click="handleClose">
        {{ $t("popup.cancel") }}
      </p>
    </div>
    <div v-if="isCustom" class="custom-popup custom">
      <img
        class="close-btn"
        src="@/assets/img/icon/close.png"
        alt="close"
        @click="customDetail.handleClose"
      />
      <img
        loading="lazy"
        :src="require(`@/assets/img/icon/${customDetail.icon.name}.svg`)"
        :alt="customDetail.icon.name"
      />
      <p class="text">{{ customDetail.boldText }}</p>
      <p>{{ customDetail.desc }}</p>
      <div>
        <p
          v-for="(item, i) in customDetail.btnArr"
          :key="i"
          :class="['p-btn', item.class]"
          @click="item.callback($event)"
        >
          {{ item.text }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "customPopup",
  props: {
    text: {
      type: String,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    isSuccess: {
      type: Boolean,
      default: false,
    },
    isWarning: {
      type: Boolean,
      default: false,
    },
    isCustom: {
      type: Boolean,
      default: false,
    },
    customConfirm: {
      type: Object,
    },
    isWaiting: {
      type: Boolean,
      default: false,
    },
    isStopSub: {
      type: Boolean,
      default: false,
    },
    accountPresence: {
      type: Boolean,
      default: false,
    },
    customDetail: {
      type: Object,
    },
  },
  data() {
    return {
      confirmText: "",
      offsetY: 0,
      viewportHeight: 0,
      selectedReason: -1,
      specificReason: "",
      options: this.$t("popup.options"),
      inFeedback: false,
    };
  },
  methods: {
    handleOk() {
      this.$parent.isSuccess = false;
      this.$parent.isError = false;
    },
    handleConfirm() {
      this.$parent.handleConfirm();
    },
    handleCancel() {
      this.$parent.isWarning = false;
    },
    handleClose() {
      this.$parent.isStopSub = false;
    },
    handleSubmit() {
      this.$parent.handleSubmit();
    },
    toLogin(e) {
      this.$parent.isError = false;
      this.$navigateTo(e, "/login");
    },
  },
  computed: {
    ...mapGetters(["screenWidth"]),
    canComfirm() {
      return this.selectedReason == 1 ? true : this.specificReason != "";
    },
    reason() {
      const arr = ["Software Issues", "Price", " Other"];
      return `事件：暂停订阅，原因：${arr[this.selectedReason]}, 具体：${
        this.specificReason
      }`;
    },
  },
  mounted() {
    window.addEventListener("message", e => {
      const d = e.data;
      switch (d.event) {
        case "setYoffset":
          this.offsetY = d.offsetY;
          this.viewportHeight = d.height;
          break;
      }
    });
  },
  watch: {
    selectedReason() {
      this.specificReason = "";
    },
  },
};
</script>

<style scoped lang="scss">
.popup-continer {
  z-index: 10000;
  position: fixed;
  margin: 0 !important;
  bottom: 0;

  &::before {
    content: "";
    background: rgb(0 0 0 / 25%);
    display: block;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
  }
}

.custom-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  border-radius: 20px 20px 0 0;
  padding: 40px 20px;
  background: #fff;
  box-shadow: 0 24px 40px 0 rgb(0 0 0 / 40%), 0 0 1px 0 rgb(0 0 0 / 75%);
  backdrop-filter: blur(50px);
  box-sizing: border-box;

  @include columnGap(18px);

  img {
    width: 80px;
    height: 80px;
  }

  > div {
    width: 100%;

    p:first-child {
      margin-bottom: 10px;
    }
  }

  .p-btn {
    width: 100%;
  }

  .text {
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    width: 100%;
    overflow: hidden;
  }

  .popup-cancel {
    background: #f2f2f2;

    &:hover {
      background: #ccc;
      color: #000;
    }
  }

  &.custom {
    position: relative;
    padding: 30px 20px;
    box-sizing: border-box;
    max-width: 100vw;

    p {
      text-align: center;
    }

    .text {
      font-weight: 700;
      margin: 20px 0 -20px;
      line-height: 40px;
    }

    .close-btn {
      position: absolute;
      right: 16px;
      top: 16px;
      filter: brightness(0) saturate(100%) invert(96%) sepia(0%) saturate(11%)
        hue-rotate(135deg) brightness(90%) contrast(85%);
      width: 20px;
      height: 20px;
    }

    @include breakpoint-max(sm) {
      p:nth-of-type(2) {
        line-height: 24px;
        font-size: 14px;
      }
    }
  }
}

.warn-popup {
  .red-btn {
    background: #d80000;
    color: #fff;

    &:hover {
      background: #000;
    }
  }

  .p-btn.loading {
    position: relative;
    font-size: 0;

    &::before {
      position: absolute;
      content: "";
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 2px solid #fff;
      border-top-color: transparent;
      animation: circle infinite 0.75s linear;
    }
  }
}

.stop-popup {
  display: flex;
  align-items: flex-start;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    height: 30px;

    @include rowGap(10px);

    &:last-of-type {
      margin-bottom: 30px;
    }

    span {
      flex-shrink: 0;
    }
  }

  input[type="radio"] {
    width: 20px;
    accent-color: #6086e8;
    flex-shrink: 0;
    cursor: pointer;

    &:focus {
      border: none;
      outline: none;
    }
  }

  input[type="text"] {
    border-width: 0 0 1px;
    border-radius: 0;
    padding: 2px;
    border-color: #000;
    flex-grow: 1;
    height: 30px !important;

    &:focus {
      outline: none;
    }
  }

  .p-btn {
    width: 100%;
  }
}

@include breakpoint-min(lg) {
  .popup-continer {
    bottom: auto;
    top: 50%;

    .custom-popup {
      width: 420px;
      padding: 40px;
      border-radius: 10px;
      transform: translateY(-50%);
    }
  }
}
</style>
